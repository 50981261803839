import React from "react"
import NewsletterForm from "../../components/NewsletterForm"
import SpherebyhorizenControl from "../../sections/OldDesign/spherebyhorizen/SpherebyhorizenControl"
import SpherebyhorizenHelmet from "../../sections/OldDesign/spherebyhorizen/SpherebyhorizenHelmet"
import SpherebyhorizenHero from "../../sections/OldDesign/spherebyhorizen/SpherebyhorizenHero"
import SpherebyhorizenSecureEasyPowerful from "../../sections/OldDesign/spherebyhorizen/SpherebyhorizenSecureEasyPowerful"
import Layout from "../../templates/Layout"

const Spherebyhorizen = () => {
    return (
        <Layout>
            <div className="bg-horizen-dark-blue">
                <SpherebyhorizenHelmet />
                <SpherebyhorizenHero />
                <SpherebyhorizenSecureEasyPowerful />
                <SpherebyhorizenControl />
                <NewsletterForm />
            </div>
        </Layout>
    )
}

export default Spherebyhorizen
