import React from "react"
import Button from "../../../components/OldDesign/Button"
import MacImg from "../../../assets/OldDesign/spherebyhorizen/os-mac.png"
import WindowsImg from "../../../assets/OldDesign/spherebyhorizen/os-windows.png"
import LinuxImg from "../../../assets/OldDesign/spherebyhorizen/os-linux.png"
import { URLProvider } from "../../../utils/URLProvider"

interface DownloadButtonProps {
    text: string
    alt: string
    img: string
    url: string
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
    text,
    alt,
    img,
    url,
}) => {
    return (
        <Button newTab width="fit" href={url}>
            <div className="flex justify-center items-center text-xl sm:w-36">
                <img className="h-6 mr-5" src={img} alt={alt} />
                {text}
            </div>
        </Button>
    )
}

const DownloadButtons = () => {
    return (
        <div>
            <p className="font-proxima text-2xl font-bold text-center mb-5">
                DOWNLOAD APP NOW
            </p>

            <div className="flex justify-center flex-wrap gap-5">
                <DownloadButton
                    url={URLProvider.URL_SPHEREBYHORIZEN_DOWNLOAD_SPHERE_MAC}
                    text="MAC"
                    img={MacImg}
                    alt="Mac logo"
                />
                <DownloadButton
                    url={URLProvider.URL_SPHEREBYHORIZEN_DOWNLOAD_SPHERE_LINUX}
                    text="LINUX"
                    img={LinuxImg}
                    alt="Linux logo"
                />
                <DownloadButton
                    url={
                        URLProvider.URL_SPHEREBYHORIZEN_DOWNLOAD_SPHERE_WINDOWS
                    }
                    text="WINDOWS"
                    img={WindowsImg}
                    alt="Windows logo"
                />
            </div>
        </div>
    )
}

export default DownloadButtons
