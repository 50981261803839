import React from "react"
import { Helmet } from "react-helmet"

const SpherebyhorizenHelmet = () => {
    return (
        <Helmet>
            <title>Sphere by Horizen - A Multifunctional Wallet</title>
            <meta
                property="og:title"
                content="Sphere by Horizen - A Multifunctional Wallet"
            />
            <meta
                name="twitter:title"
                content="Sphere by Horizen - A Multifunctional Wallet"
            />
            <meta name="theme-color" content="#041742" />
            <meta
                name="description"
                content="Sphere by Horizen is a multifunctional wallet that interfaces with most Horizen products and services, allowing users to securely store and transact ZEN."
            />
            <meta
                property="og:description"
                content="Sphere by Horizen is a multifunctional wallet that interfaces with most Horizen products and services, allowing users to securely store and transact ZEN."
            />
            <meta
                name="twitter:description"
                content="Sphere by Horizen is a multifunctional wallet that interfaces with most Horizen products and services, allowing users to securely store and transact ZEN."
            />
            <meta
                property="og:image"
                content="https://www.horizen.io/assets/images/meta/sphere.jpg"
            />
            <meta
                name="twitter:image"
                content="https://www.horizen.io/assets/images/meta/sphere.jpg"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
                name="keywords"
                content="sphere by horizen, sphere wallet, zen sphere wallet"
            />
            <link
                rel="canonical"
                href="https://www.horizen.io/wallets/spherebyhorizen/"
            />
            <meta
                property="og:url"
                content="https://www.horizen.io/wallets/spherebyhorizen/"
            />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Horizen" />
            <meta name="twitter:site" content="@horizenglobal" />
            <meta name="twitter:creator" content="@horizenglobal" />
        </Helmet>
    )
}

export default SpherebyhorizenHelmet
