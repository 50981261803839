import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"
import SectionTitle from "../../../components/OldDesign/SectionTitle"
import ContentContainer from "../../../templates/ContentContainer"
import img from "../../../assets/OldDesign/spherebyhorizen/feature-1.png"

gsap.registerPlugin(ScrollTrigger)

const SpherebyhorizenControl = () => {
    const imgRef = useRef<HTMLImageElement>(null)
    const listBoxClassName =
        "border-t border-[#c4c8cb] first-of-type:border-t-0 py-6"

    const listTitleClassName =
        "font-proxima text-old-design-dark-blue text-lg font-bold"

    const listItemClassName = "ml-8 py-1 first-of-type:mt-2"

    useEffect(() => {
        if (!imgRef.current) return
        gsap.from(imgRef.current, {
            x: 25,
            opacity: 0,
            duration: 0.5,
            scrollTrigger: {
                trigger: imgRef.current,
                start: "top 75%",
            },
        })
    }, [])

    return (
        <section className="bg-old-design-gray py-12">
            <ContentContainer>
                <div className="grid grid-cols-1 md:grid-cols-2">
                    <div>
                        <img
                            ref={imgRef}
                            src={img}
                            className="-mt-10 max-h-[600px] mx-auto"
                            alt="sphere wallet"
                        />
                    </div>
                    <div className="mt-10 md:mt-0">
                        <SectionTitle
                            alignment="left"
                            text="HAVE CONTROL OVER YOUR FINANCES"
                        />

                        <div className={listBoxClassName}>
                            <h4 className={listTitleClassName}>
                                Supports both lite and full clients
                            </h4>
                        </div>

                        <div className={listBoxClassName}>
                            <h4 className={listTitleClassName}>
                                Wallet Functionality
                            </h4>
                            <ul className="font-proxima text-old-design-dark-blue font-light list-disc">
                                <li className={listItemClassName}>
                                    Supports a web wallet and paper wallet
                                </li>
                                <li className={listItemClassName}>
                                    Funds can be restored using a seed phrase
                                </li>
                                <li className={listItemClassName}>
                                    Supports backward and forward transfers
                                    between Horizen mainchain and sidechains
                                </li>
                            </ul>
                        </div>
                        <div className={listBoxClassName}>
                            <h4 className={listTitleClassName}>
                                Runs on desktop
                            </h4>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default SpherebyhorizenControl
