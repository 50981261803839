import React from "react"
import SectionTitle from "../../../components/OldDesign/SectionTitle"
import ContentContainer from "../../../templates/ContentContainer"
import DownloadButtons from "./DownloadButtons"

const SpherebyhorizenSecureEasyPowerful = () => {
    return (
        <section className="bg-white pt-16 pb-12">
            <ContentContainer>
                <SectionTitle text="SECURE, EASY, POWERFUL" />
                <p className="text-center font-proxima text-old-design-dark-blue text-2xl font-medium mt-5">
                    Sphere by Horizen is a multifunctional app that allows
                    people to take control of their finances.
                </p>

                <p className="text-old-design-blue font-proxima text-2xl font-bold text-center mt-10 mb-4">
                    Horizen’s ecosystem starts here!
                </p>

                <DownloadButtons />
            </ContentContainer>
        </section>
    )
}

export default SpherebyhorizenSecureEasyPowerful
